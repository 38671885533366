.edit-invetory-left {
  padding: 1em !important;
  background-color: white;
  border-radius: 0.75rem !important;
  // background: var(--POS-Light, #FCFAFC);
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);
}
.iframe-edit-inventory {
  height: 700px;
}
.iframe-website {
  width: 100%;
  height: 100%;
  border-radius: 0.75em;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
}
.iframe-website-edit-inventory {
  width: 100%;
  min-height: 450px;
  height: 100%;
  border-radius: 0.75em;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
}
.left-container {
  // background: var(--POS-Light, #FCFAFC);
}
.stolen-card-text {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: 0.03em;
  text-align: left;
  color: #232226;
}

.price-market-text,
.price-market-amount {
  color: #fcfafc;
  font-size: 1.1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.54px;
  padding-left: 0px;
  padding-right: 0px;
}

.ebay-sale-btn {
  width: 100%;
  height: 40px;
  border-radius: var(--8, 8px);
  background: rgba(177, 177, 177, 0.23);
  color: #fcfafc;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.64px;
  border: none;
  margin-top: 1.8em;
}

.price-charting-btn {
  width: 100%;
  height: 40px;
  border-radius: var(--8, 8px);
  background: rgba(0, 118, 213, 0.9);
  color: #fcfafc;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.64px;
  border: none;
  margin-top: 1.8em;
}

.suggest-text,
.suggest-amount {
  color: var(--POS-Gray-5, #918e95);
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
  letter-spacing: -0.42px;
  padding-left: 0px;
  padding-right: 0px;
}

.detail-div {
  margin-bottom: 10px;

  p {
    color: #9e9e9e;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.64px;
    margin-bottom: 0px !important;
  }

  span {
    color: #fcfafc;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.54px;
  }

  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eae8ec;
  padding: 0px;
  padding-bottom: 1em;
}

.horizental-line {
  width: 100%;
  height: var(--0, 1px);
  border-radius: var(--0, 0px);
  background: #eae8ec;
}

.main-row {
  // flex-wrap: nowrap !important;
  // gap: 10px;
  padding-right: 7px;
}

.product-stats {
  padding: 1.1em;
  border-radius: var(--16, 16px);
  background: #2f2f2f;
}

.product-stats-text {
  color: #fcfafc;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.6px;
  margin-bottom: 30px;
  padding: 0px;
}

.market-price-div {
  padding: 1.1em;
  border-radius: var(--16, 16px);
  background: #2f2f2f;
}

.edit-invetory-right {
  border-radius: 0.75em;
  // background: var(--POS-Sidebar-color, #222024);
  padding: 0px 0px 0px 1em !important;
  // display: flex;
  // flex-direction: column;
  // row-gap: 1.2em;
}

.right-container {
  border-radius: 0.75em;
  background: var(--POS-Sidebar-color, #222024);
  display: flex;
  flex-direction: column;
  row-gap: 1.2em;
  padding: 1em !important;
}

.completeBoxDiv {
  border-radius: var(--16, 16px);
  background: #2f2f2f;
}

.completeBoxDiv {
  padding: 1.1em;
}

.segaGenesisDiv {
  border-radius: var(--16, 16px);
  background: #2f2f2f;
  padding: var(--16, 16px);
}

.complete-number {
  overflow: hidden;
  color: $WHITE_COLOR;
  text-overflow: ellipsis;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-top: 0.75em;
  padding-left: 0px;
  padding-right: 0px;
}

.complete-box {
  color: #fcfafc;
  font-size: 1.1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
  margin-top: 1.5em;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.para-Text {
  color: #fcfafc;
  font-size: 2em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.96px;
  padding: 0px !important;
}

.pricing-Div {
  border-radius: 0.75rem;
  border: 1px solid rgb(0, 0, 0, 0.3);
  padding: 1em;
}

.img-Div img {
  width: 8em;
  height: 8em;
  object-fit: contain;
}
.img-Div-stolen img {
  width: 7em;
  height: 7em;
  object-fit: contain;
}

.saga-Text {
  color: var(--POS-Gray-8, #232226);
  font-size: 1.25em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.6px;
  margin-bottom: 10px;
}

.game-Control {
  color: var(--POS-Gray-8, #232226);

  /* Regular/16 */
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
}

.sku-Text {
  color: #000;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
}

.sku-Num-Text {
  overflow: hidden;
  color: var(--POS-Gray-5, #918e95);
  text-overflow: ellipsis;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
}

.pricing-Text {
  color: var(--POS-Gray-8, #232226);

  font-size: 1.25em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
}

.skitchin-Items {
  border-radius: var(--24, 24px);
  background: $PURPLE_COLOR;
  color: #fcfafc;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
  padding: 6px var(--8, 8px);
}

.category-skitchin {
  background-color: $PURPLE_COLOR;
  font-size: 0.8em;
}

.edit-inventory-product-image {
  box-shadow: none !important;
  img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 0.75em !important;
  }
}

.pricecharting-icon {
  width: 2rem;
}

.edit-items-detail-wrapper {
  border: 1px solid rgb(0, 0, 0, 0.3);
  border-radius: 0.75rem;
}
