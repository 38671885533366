.notification-container {
  position: relative;
  color: $DARK_GREY;

  .notification-toggle-button {
    border: none;
    background-color: transparent;
    color: $WHITE_COLOR;
    border-radius: 100px;
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;

    .notification-count {
      min-width: 2em !important;
      min-height: 2em !important;
      color: $WHITE_COLOR;
      font-size: 0.8em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      position: absolute;
      top: -1%;
      right: -1%;
      padding: 0em;
      padding-right: 0.1em;
      margin: 0em;
      background-color: $PURPLE_COLOR;
      transition: 0.3s ease;
    }

    .max-notification-count {
      min-width: 2.5em !important;
    }
  }

  .notification-toggle-button:hover {
    color: $WHITE_COLOR;
    background-color: $PURPLE_COLOR !important;
    .notification-count {
      color: $PURPLE_COLOR;
      background-color: $WHITE_COLOR;
    }
  }

  .active-notification-toggle-button {
    color: $WHITE_COLOR;
    background-color: $PURPLE_COLOR;
    .notification-count {
      color: $PURPLE_COLOR;
      background-color: $WHITE_COLOR;
    }
  }
}

.notification-dropdown {
  position: absolute;
  top: 110%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1em;
  z-index: 1;
  border-radius: 0.75em;
  min-width: 30em;
  max-width: 30em;
  display: flex;
  flex-direction: column;
  align-items: start;
  z-index: 99;

  .notification-heading {
    font-size: 1.4em;
    font-weight: 700;
  }

  .notifications-wrapper {
    max-height: 25em;
    width: 100%;
  }

  .react-tabs {
    width: 100%;
  }

  .react-tabs__tab-list {
    display: flex !important;
    align-items: center;
    gap: 0.7em;
    padding-left: 0em !important;
    margin-bottom: 0px !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    width: 100% !important;

    li {
      list-style: none !important;
      padding: 0.3em 0em;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      transition: 0.3s ease;
      margin-bottom: -2px !important;
      margin-right: 0.5em;
      display: flex;
      align-items: center;
      gap: 0.5em;

      span {
        background-color: $DARK_GREY;
        border-radius: 5px;
        padding: 0.2em 0.7em 0.1em 0.5em;
        color: $WHITE_COLOR;
        font-size: 0.8em;
        margin-left: 0.2em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    li:hover {
      border-bottom-color: $DARK_GREY;
    }
    .react-tabs__tab--selected {
      color: $PURPLE_COLOR;
      border-bottom-color: $PURPLE_COLOR;

      span {
        background-color: $PURPLE_COLOR;
      }
    }
  }
}

.notification-item {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $lightGreyBgColor;
  padding: 0.5em;
  border-radius: 0.75em;
  border: 1px solid transparent;
  transition: 0.4s ease;

  img {
    min-width: 5em;
    min-height: 5em;
    max-width: 5em;
    max-height: 5em;
    margin-right: 1em;
  }

  .product-notification-content-container {
    display: flex;
    flex-direction: column;
    gap: 0.1em;

    p {
      margin: 0em !important;
      max-width: 15em;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 0.3em;
    }

    p:first-child {
      font-size: 1.1em;
      font-weight: bold;
    }
    p:last-child {
      font-size: 0.9em;
      opacity: 0.7;
      max-width: 100%;
      text-wrap: wrap;
    }
    .sku-number-text {
      color: $PURPLE_COLOR;
      display: flex;
      justify-content: start;
      align-items: center;
      p {
        font-size: 0.8em;
      }

      .copy-button {
        border: none;
        background-color: transparent;
      }
    }
  }
}

.notification-item:hover {
  border-color: $PURPLE_COLOR;
}

.read-notification-item {
  filter: grayscale(100%);
  opacity: 0.7;
  cursor: default !important;
}

.mark-all-as-read-button {
  background-color: transparent;
  border: none;
  color: $DARK_GREY;
  transition: 0.4s ease;
}

.mark-all-as-read-button:hover {
  color: $PURPLE_COLOR;
}

.mark-as-read-button {
  min-width: 1.5em;
  min-height: 1.5em;
  border: none;
  background-color: $PURPLE_COLOR;
  border-radius: 100px;
  padding: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.1em;
  transition: 0.4s ease;

  .read-icon {
    color: $WHITE_COLOR;
    width: 0.7em;
    height: 0.7em;
    position: absolute;
    transition: 0.4s ease;
  }
}

.mark-as-read-button:hover {
  background-color: $WHITE_COLOR;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
  .read-icon {
    color: $PURPLE_COLOR;
  }
}

.notification-print-button {
  border: 1px solid transparent;
  background-color: $PURPLE_COLOR;
  color: $WHITE_COLOR;
  padding: 0.5em 0.5em;
  text-wrap: nowrap;
  border-radius: 0.5em;
  transition: 0.3s ease-in-out;
}

.notification-print-button:hover {
  border-color: $PURPLE_COLOR;
  background-color: transparent;
  color: $PURPLE_COLOR;
}

.print-message-text {
  font-weight: normal !important;
  font-size: 1em !important;
}

.notification-date-time-text {
  font-size: 0.8em !important;
}

.other-print-message {
  font-size: 1.1em !important;
  font-weight: bold !important;
}

.notification-print-button:focus {
  border: none;
}
