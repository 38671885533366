.date-picker-dropdown-width {
  min-width: 25em;
  max-width: 25em;
}

.filter-dropdowns-type {
  max-width: 13em;
}

.filter-dropdowns-activity-type {
  max-width: 15em;
}
