.price-changes-graph-wrapper {
  background-color: $WHITE_COLOR;
  padding: 1em 0.5em;
  border-radius: 0.75em;
  height: 240px;
  max-height: 240px;
}

.threshold-filter-wrapper {
  background-color: $WHITE_COLOR;
  padding: 1em 0.5em;
  border-radius: 0.75em;
  height: calc(100vh - 365px);
  max-height: calc(100vh - 365px);
}

.price-charting-graph {
  max-height: 100px !important;
}

.price-chart-card-heading {
  font-size: 1.2em;
  font-weight: 700;
}

.price-changes-card-button {
  min-width: 5em;
}

.price-change-toggle-button-wrapper {
  border: 1px solid $GREY;
  padding: 0.5em;
  min-height: 3em;
  max-height: 3em;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  position: relative;
  min-width: 100%;
}

.discount-toggle-button {
  background-color: rgba(0, 0, 0, 0.17) !important;
  border-radius: 0.75rem !important;
  width: 8em !important;
  min-width: 8em !important;
  padding: 0em !important;

  .price-change-toggle-button-background {
    border-radius: 0.75em !important;
    margin: 0em !important;
    height: 100% !important;
    background-color: $PURPLE_COLOR;
  }
  .by-price-change-active {
    left: 50% !important;
  }
  .price-change-active-button {
    color: $WHITE_COLOR;
    font-weight: 600;
  }
}

.dashboard-card-button {
  background-color: rgba(0, 0, 0, 0.17) !important;
  border-radius: 100px !important;
  width: 8em !important;
  min-width: 8em !important;
  padding: 0em !important;
  min-height: 2em !important;
  max-height: 2em !important;
  font-size: 0.7em !important;

  button {
    padding: 0em !important;
  }
  .price-change-toggle-button-background {
    border-radius: 100px !important;
    margin: 0em !important;
    height: 100% !important;
    background-color: $PURPLE_COLOR;
  }
  .by-price-change-active {
    left: 50% !important;
  }
  .price-change-active-button {
    color: $WHITE_COLOR;
    font-weight: 400;
  }
}

.price-change-toggle-button {
  border-radius: 100px;
  background-color: transparent;
  max-height: 100% !important;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $DARK_GREY;
  padding: 0em 2em;
  text-wrap: nowrap;
  z-index: 2;
  width: 50% !important;
  transition: 0.1s ease all;
}

.price-change-active-button {
  color: $PURPLE_COLOR;
  font-weight: 600;
}

.price-change-toggle-button-background {
  background-color: $LIGHT_PURPLE_COLOR;
  position: absolute;
  height: 80%;
  min-width: 50%;
  margin: 0.4em;
  border-radius: 100px;
  left: 0%;
  transition: 0.4s ease;
}

.by-price-change-active {
  left: 47%;
}

.price-change-product-info-card {
  background-color: $WHITE_COLOR;
  border-radius: 0.75em;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
  padding: 1em;

  .product-image-wrapper {
    width: 6em;
    height: 6em;
    border-radius: 0.75em;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .product-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0.1em;
    align-content: space-between;
  }

  .product-stats-wrapper {
    span:last-child {
      font-size: 1.1em;
      font-weight: 700;
    }
  }
}

.stock-price-field {
  min-width: 10em;
}

.threshold-vales-wrapper {
  max-height: calc(100vh - 540px);

  .delete-dropdown-button {
    padding: 0em;
    background-color: transparent;
    border: none;
    transition: 0.4s ease;
  }
  .delete-dropdown-button:hover {
    color: $PURPLE_COLOR !important;
  }
}
.global-field-heading {
  background-color: $GREY;
  padding: 0.8em;
  border-radius: 0.75em;
  max-height: 3em;
  min-height: 3em;
  display: flex;
  align-items: center;
}
.minimum-number-field {
  input {
    background-color: $GREY;
  }
}

.add-dropdown-button {
  background-color: $PURPLE_COLOR;
  border: none;
  width: 2em;
  height: 2em;
  color: $WHITE_COLOR;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.price-changes-dismissed {
  opacity: 0.5;
}

.max-input-width {
  max-width: 7em;
}

.filter-section-wrapper {
  background-color: $WHITE_COLOR;
  border-radius: 0.5em;
  margin-bottom: 1em;
}

.filter-heading {
  font-size: 1.5em;
  font-weight: 600;
}

.filter-small-heading {
  font-size: 1.1em;
  font-weight: 600;
}

.delete-button-style {
  border: none;
  background-color: transparent;
  padding: 0px !important;
}

.price-change-filter-tag {
  background-color: $LIGHT_PURPLE_COLOR;
  border-radius: 100px;
  text-wrap: nowrap;
  padding: 0.5em 1em;
}

.filter-tags-heading {
  padding: 0.5em 0em;
  background-color: transparent !important;
}

.price-changes-content-wrapper {
  span:first-child {
    font-size: 1.3em !important;
    font-weight: 700;
  }

  .price-change-product-properties {
    span {
      font-size: 1em !important;
      font-weight: 400;
      opacity: 0.5;
    }
  }
}
