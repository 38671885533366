.tab-link-wrapper {
  .nav-link {
    color: $PURPLE_COLOR;
    min-height: 3em;
    margin: 0.5em 0em;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .active {
    background-color: $PURPLE_COLOR !important;
    color: $WHITE_COLOR !important;
  }
  .nav-link:hover {
    background-color: $LIGHT_PURPLE_COLOR;
  }
}

.tab-heading {
  font-family: "neue-june-demi";
}

.tab-sub-heading {
  font-family: "neue-june-demi";
}

.connected-printer-heading {
  font-size: 1.1em !important;
}
