.download-summary-button {
  border: none;
  background-color: transparent;
  padding: 0 1em 0 1em;
  transition: 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: $PURPLE_COLOR !important;
  }
  svg {
    width: 1.8em;
    height: 1.8em;
  }
}

.sales-card-wrapper {
  min-height: 40vh !important;
  display: flex;
  justify-content: start !important;
  align-items: stretch !important;
}

.sales-summary-wrapper {
  max-height: calc(100vh - 160px) !important;
}

.sales-summary-heading {
  font-size: 1.2rem !important;
  font-weight: 700;
}

.sales-summary-detail-heading {
  font-size: 1.2rem !important;
  font-weight: 600;
  opacity: 0.7;
}

.sales-summary-description {
  font-size: 1.2rem !important;
}

.header-border-dark-border {
  border-top: 1px solid rgb(0, 0, 0, 0.5);
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}
.footer-border-dark {
  border-bottom: 1px solid rgb(0, 0, 0, 0.5);
}

.detail-div-border {
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}

.noPrint {
  display: none;
}
.sale-summary-report-heading {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
}
@media print {
  .noPrint {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
