.remove-minWidth-dropdown {
  min-width: 0em !important;
}
.addTrade-gray-text {
  font-size: 0.87em;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -0.04em;
  text-align: left;
  color: #918e95 !important;
}
.input-background {
  input {
    background: #f4f4f4;
  }
  svg {
    background: #f4f4f4;
  }
}
