.modal-header {
  border-bottom: none !important;
}
.modal-footer {
  border-top: none !important;
}
.discount-modal-product {
  display: flex;
  align-items: center;
  img {
    width: 56px;
    height: 56px;
  }
}
.modal-product-div {
  border-bottom: 1px solid #eae8ec;
}
.modal-product-name {
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 0px;
  color: #232226;
}
.modal-product-box {
  font-size: 0.87em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 0px;
  color: #918e95;
}
