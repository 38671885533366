.add-inventory-search-field {
  min-width: 15em;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);
}

.add-inventory-modal {
  z-index: 9999;
  .modal-content {
    background-color: $lightGreyBgColor;
    border-radius: 0.75em;
    padding: 0.75em;
  }
  .modal-dialog {
  }
  backdrop-filter: blur(2px);
}

.iframe-opened {
  padding: 1em 5em !important;
  .modal-content {
    min-width: auto !important;
    margin: auto !important;
    margin-left: auto !important;
  }
  .modal-dialog {
    margin-left: 0px !important;
    min-width: 100% !important;
  }
}

.add-inventory-modal-header {
  border-bottom: none;
  .add-inventory-modal-header-name {
    font-size: 0.9em;
    font-weight: bold;
    color: $LIGHT_GREY_TEXT_COLOR;
    text-wrap: nowrap;
  }
}

.add-inventory-modal-body {
}

.bulk-modal {
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.4);
}

.add-inventory-dropdowns-category {
  min-width: 8em;
}

.add-inventory-dropdowns-type {
  min-width: 8em;
}

.add-inventory-search-product {
  min-width: 8em;
}

.add-inventory-hero-wrapper {
  background-color: $WHITE_COLOR;
  border-radius: 0.75em;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.04),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
  padding: 0.85em;
}

.add-inventory-product-image {
  cursor: pointer;
  width: 8em;
  height: 8em;
  min-width: 8em;
  position: relative;
  border-radius: 0.75em;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  button {
    right: -0.6em;
    bottom: -0.6em;
  }
}

.price-change-product-image {
  overflow: hidden !important;
  padding: 0em !important;
  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}
.product-content-wrapper {
  color: $DARK_GREY;
  font-weight: bold;
  font-size: 1.1em;

  span:first-child {
    font-size: 1.1em;
    font-weight: bold;
  }

  span:nth-child(3) {
    color: $LIGHT_GREY_TEXT_COLOR;
  }
}

.add-inventory-prices-detail-wrapper {
  color: $WHITE_COLOR;
  background-color: $DARK_GREY;
  border-radius: 0.75em;
  font-weight: 400;
  padding: 0.75em;

  .price-stat-con {
    display: flex;
    flex-direction: column;
    background: #2f2f2f;
    padding: 0.75em;
    border-radius: 0.55em;
    gap: 0.5em;

    .price-stat-heading {
      color: #9e9e9e;
    }
    span:nth-child(2) {
      font-size: 1em;
    }
  }
  .multi-price-stat-con {
    span:first-child {
      color: #9e9e9e;
    }
    background: #2f2f2f;
    padding: 0.75em;
    border-radius: 0.55em;
  }
}

.product-stats-con-height {
  max-height: $PRODUCT_STATS_CON_MAX_HEIGHT;
  overflow-y: scroll;
}

.add-inventory-price-heading {
  span {
    font-size: 1em !important;
  }
}

.add-inventory-price-sub-heading {
  span {
    color: $LIGHT_GREY_TEXT_COLOR;
  }
}

.add-inventory-stock-price-wrapper {
  background-color: $WHITE_COLOR;
  padding: 0.75em;
  border-radius: 0.75em;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.04),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
}

.add-inventory-quantity-field {
  min-width: 7em;
}

.add-inventory-serial-number-field {
  min-width: 20em;
}

.condition-dropdown {
  min-width: 17em;
  max-width: 17em;
}

.add-inventory-price-field {
  min-width: 10em;
}

.dollar-icon {
  width: 0.7em;
}

/* App.css */
.tag {
  background-color: $lightGreyBgColor;
  border-radius: 100px;
  padding: 0.5em 0.5em 0.5em 1em;
  margin-right: 0.5em;
  margin-bottom: 0.3em;
  margin-top: 0.3em;
  color: $DARK_GREY;
  min-height: 2.5em;
  max-height: 2.5em;
  display: flex;
  gap: 0.5em;
  align-items: center;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  button {
    background-color: transparent !important;
    color: $DARK_GREY;
    border: none;
    padding: 0;
  }
}

.selected {
  background-color: $PURPLE_COLOR !important;
  color: $WHITE_COLOR !important;
  button {
    color: $WHITE_COLOR !important;
  }
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 10em;
}

.add-tag-wrapper {
  background-color: $lightGreyBgColor;
  padding: 0.2em;
  border-radius: 100px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;

  button {
    background-color: transparent !important;
    color: $DARK_GREY;
    border: none;
  }
}

.input-tag-field {
  border: 1px solid transparent;
  border-radius: 100px;
  padding: 0em 0.5em;
  height: 100%;
  min-height: 2.5em;
  max-height: 2.5em;
}

.input-tag-field:focus {
  outline: none;
  border: 1px solid $PURPLE_COLOR;
}

.add-tag-icon {
  width: 1em !important;
  height: 1em !important;
}

.iframe-website {
  width: 100%;
  height: 100%;
  border-radius: 0.75em;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
}

.iframe-toogle-button {
  border: none;
  color: $WHITE_COLOR;
  width: 3em;
  height: 3em;
  position: absolute;
  right: -1.5em;
  top: 50%;
  transform: rotate(180deg);
  background-color: transparent;
}
.iframe-toggle-icon {
  width: 100%;
  height: 100%;
}

// add batch inventory modal
.modal-90w {
  max-width: 95%;
}

.inventory-batch-card {
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 0.5em;
  background-color: $WHITE_COLOR;
  padding: 0.6em;
  border-radius: 0.75em;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);

  p {
    margin: 0;
  }

  .card-heading {
    font-size: 1em;
    color: $TABLE_HEADER_TEXT_COLOR;
  }

  .card-description {
    font-size: 1.25em;
    font-weight: 600;
  }
  .total-cost-text {
    background-color: $lightGreyBgColor;
    border-radius: 0.75em;
    padding: 1em;
    pointer-events: none;
    input {
      background-color: transparent;
      border: 3px solid transparent;
      outline: unset;
      text-align: right;
      font-size: 1.25em;
      font-weight: 600;
    }
    span {
      font-size: 1.25em;
      font-weight: 600;
    }
  }
}

.inventory-inner-batch-card {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 0.5em;
  background-color: $lightGreyBgColor;
  padding: 0.6em;
  border-radius: 0.75em;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);

  p {
    margin: 0;
  }

  .inner-card-heading {
    font-size: 1em;
    color: $TABLE_HEADER_TEXT_COLOR;
  }

  .inner-card-description {
    font-size: 1.25em;
    font-weight: 600;
  }
}
.batch-total-card {
  min-height: 7em;
}

.quantity-top-margin {
  margin-top: 0.7em;
}

.trading-card-warning-text {
  span {
    font-size: 0.9em !important;
    color: red;
  }
}

.nested-modal-z-index {
  z-index: 9999;
  backdrop-filter: blur(2px);
}
