.dashboard-card-heading {
  width: 7em !important;
}
.dashboard-icon-skeleton {
  width: 2em !important;
  height: 2em !important;
  border-radius: 100% !important;
}
.graph-skeleton {
  height: 160px !important;
}
