// @import "./neueJunefontFamily.scss";
// @import "./montserratFont.scss";
@import "./interFontFamily.scss";

* {
  font-family: "neue-june-regular";
  font-size: 12px;
  font-weight: 400;
}

body {
  background-color: $lightGreyBgColor;
  height: 100%;

  @media (min-width: $LG_SCREEN_BREAKPOINT) {
    overflow: hidden;
  }
}

.toast-message-popup {
  z-index: 1000000;
}

.logo-image-table {
  width: 3em;
  height: 3em;
  background: cover;
  border-radius: 0.5em;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.2);
}

.text-purple {
  color: $PURPLE_COLOR;
}

.text-red {
  color: $ERROR_MESSAGE_COLOR;
}

.collect-pos-logo-image {
  height: 3em !important;
  max-height: 3em;
}

.store-inactive {
  cursor: not-allowed !important;
  li {
    pointer-events: none;
  }
}
.store-inactive li:first-child {
  pointer-events: all;
}

.category-dropdowns {
  min-width: 13em !important;
}
.trade-search-filter {
  min-width: auto !important;
}
.error-icon {
  width: 5em !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}
