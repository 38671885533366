.modal-title {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.8em;
  letter-spacing: 0.03em;
  text-align: left;
  color: #232226;
}
.modal-content {
  background: #f4f4f4;
}
.background-color-modal {
  background-color: white;
}
.modal-header {
  border-bottom: none !important;
}
.apply-Categories-value-text {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.03em;
  text-align: left;
}
.switch-alignment {
  justify-content: start;
}
.offer-percentage-div {
  padding: 1em;
  border-radius: 0.75em;
  background: #fcfafc;
  margin-top: 0.875em;
  img {
    width: 100%;
    height: 100%;
  }
}
.trade-offer-text {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: 0.04em;
  text-align: left;
  color: #918e95;
}
.modal-footer {
  border-top: none;
  padding-top: 0px !important;
}
.max-price-text {
  width: 115px;
  height: 48px;
  padding: 0.7em 0.5em;
  border-radius: 0.7em;
  justify: space-between;
  background: #fcfafc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: 0.04em;
  text-align: left;
  color: #6b6970;
}
.Search-result-div {
  width: 100%;
  height: auto;
  padding: 1em;
  border-radius: 0.75em;
  background: #fcfafc;
  margin-top: 0.875em;
}
.Search-result-div {
  position: absolute;
  top: 35px;
  z-index: 11111;
  // display: none;
}
.category-div-search {
  height: 50px;
  align-items: center;
  padding: 0px 12px;
  border-radius: 12px;
  border: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  background-color: #f4f4f4;
  transition: 0.4s ease;
  border: 1px solid transparent;
}

.category-div-search:hover {
  cursor: pointer;
  border: 1px solid $PURPLE_COLOR;
}

.max-height {
  max-height: 150px !important;
}
.max-height-discount {
  max-height: 350px !important;
}
.discount-background {
  background: #f2f1f1 !important;
  box-shadow: 1px 1px 10px rgb(0, 0, 0, 0.3);
}

.disabled-selectfield {
  cursor: not-allowed !important;
}
