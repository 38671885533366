.input-textfield-vertical {
  border: 1px solid transparent;
  color: $DARK_GREY;
  background-color: $WHITE_COLOR;
  transition-duration: 0.6s;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
  background-color: #eae8ec;
  width: 20px;
  padding-left: $FONT-3;
}
.vertical-input-number-field-container {
  width: 24px;
  height: 63px;
  padding: $FONT-6 $FONT-4;
  border-radius: 2em;
  background: #eae8ec;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  transition-duration: 0.6s;
  button {
    background-color: transparent;
    border: none;
    color: $LIGHT_GREY_TEXT_COLOR;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
  }
  button:hover {
    color: $DARK_GREY;
  }
}

.input-textfield-vertical::placeholder {
  opacity: 0.5;
}
.vertical-input-number-field-container:hover {
  border-color: $PURPLE_COLOR;
}

.input-textfield-vertical:focus {
  outline: none;
  box-shadow: none !important;
}
