.inventory-overview-card-wrapper {
  margin-top: 1em;
  margin-bottom: 1.2em;
}

.inventory-overview-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 0.5em;
  background-color: $WHITE_COLOR;
  padding: 0.6em;
  min-width: 16em;
  max-width: 16em;
  border-radius: 0.75em;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);

  p {
    margin: 0;
  }

  .inventory-overview-card-heading {
    font-size: 1em;
    color: $TABLE_HEADER_TEXT_COLOR;
  }

  .inventory-overview-card-description {
    font-size: 1.25em;
    font-weight: 600;
  }
}

.dropdown-icon {
  position: absolute;
  left: 0.6em;
  top: 0.2em;
  width: 1.7em;
  bottom: auto;
}

.product-image {
  cursor: pointer;
  margin-right: 1em;
  width: 3em;
  max-width: 3em;
  min-height: 3em;
  max-height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.2);

  img {
    border-radius: 0.2em;
    height: auto;
    max-height: 4em;
    max-width: 4em;
    object-fit: contain;
    object-position: center;
    transition: 0.4s ease;
  }
}

.product-image:hover {
  img {
    scale: 3;
    margin-left: 5em;
    position: fixed !important;
    z-index: 9999999999;
  }
}
.console-name-react-select {
  background-color: rgb(106, 24, 237, 0.3);
  padding: 0.5em;
  border-radius: 0.5em;
  margin-right: 0.5em;
  color: $DARK_GREY;
}

.search-option-wrapper {
  line-height: 2.5em;
}

.label-modal-text-heading {
  font-family: "neue-june-demi";
  font-size: 1.1em;
}

.print-input-field {
  background-color: transparent;
  outline: none;
  border: 1px solid $TABLE_HEADER_TEXT_COLOR;
  border-radius: 0.5em;
  min-height: 2em;
  text-align: center;
  transition-duration: 0.4s;
  width: 100%;
}

.print-input-field:hover {
  border: 1px solid $PURPLE_COLOR;
}

.print-input-field:focus {
  border: 1px solid $PURPLE_COLOR;
}

.bulk-field {
  min-height: 3em;
  max-height: 3em;
  max-width: 15em;
}

.product-edit-anchor-link:hover {
  text-decoration: underline;
}

.sku-tooltip {
  min-width: 15em !important;
}

.sku-wrapper {
  font-weight: normal !important;
  font-size: 0.9em !important;
}

.add-inventory-heading {
  font-size: 1.2em;
}

.custom-item-heading {
  font-size: 1.1em;
  font-weight: bold;
}

.ag-theme-quartz {
  --ag-active-color: #6a18ed !important;
  .ag-header-row {
    background-color: $GREY;
    color: #918e95 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    min-width: 100% !important;
  }

  .ag-body {
    width: 100% !important;
    min-width: 100% !important;
  }
  .ag-row {
    width: 100% !important;
    min-height: 5em !important;
    min-width: 100% !important;
  }

  .ag-row:hover {
    background-color: rgb(234, 232, 236, 0.9) !important;
    z-index: 999;
    min-width: 100% !important;
  }

  .ag-header-container {
    background-color: $GREY !important;
  }

  .ag-cell-value {
  }

  .ag-center-cols-container,
  .ag-header-row-column {
    width: auto !important;
    min-width: 100% !important;
  }

  .ag-body-vertical-scroll {
    width: 5px !important;
    min-width: 5px !important;
    max-width: 5px !important;
    .ag-body-vertical-scroll-container {
      width: 5px !important;
      min-width: 5px !important;
      max-width: 5px !important;
    }
    .ag-body-vertical-scroll-viewport {
      width: 5px !important;
      min-width: 5px !important;
      max-width: 5px !important;
    }

    .ag-body-vertical-scroll-viewport::-webkit-scrollbar {
      width: 5px !important;
      min-width: 5px !important;
      max-width: 5px !important;
    }

    .ag-body-vertical-scroll-viewport::-webkit-scrollbar-track {
      background: transparent;
      position: absolute;
      right: 0;
    }

    .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb {
      background: $TABLE_HEADER_TEXT_COLOR;
      border-radius: 100px;
      transition: 0.3s ease-in-out;
    }

    .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb:hover {
      background-color: $PURPLE_COLOR;
    }
  }
}

.highlighted-row {
  border: 1px solid $PURPLE_COLOR !important;
  border-radius: 5px !important;
  transition: 0.3s ease;
}

.custom-tooltip {
  padding: 1em;
  border-radius: 0.5em;
  color: $WHITE_COLOR;
  background-color: $PURPLE_COLOR;

  p {
    font-size: 0.9em;
    margin: 0 !important;
  }
}

.actionTooltip {
  padding: 0.5em;
}

.current-row-editing {
  border-color: 1px solid $PURPLE_COLOR !important;
  background-color: red !important;
}

.input-textarea-wrapper {
  min-height: 8em;
  height: auto !important;
}

.input-textarea-field {
  min-height: 8em;
  max-height: 8em;
}

.expand-tab-button {
  border: none;
  background-color: transparent;
  transition: 0.2s ease-in-out;
}

.expand-tab-button :hover {
  color: $PURPLE_COLOR;
}

.tab-heading {
  font-size: 1.1em;
  opacity: 0.7;
}

.disable-dropdown-icon {
  cursor: not-allowed !important;
}
