$red: pink;
$GREY: #eae8ec;
$DARK_GREY: #222024;
$WHITE_COLOR: #fcfafc;
$BLACK_COLOR: #222024;
$PURPLE_COLOR: #6a18ed;
$lightGreyBgColor: #f4f4f4;
$LIGHT_GREY_TEXT_COLOR: #6b6970;
$BUTTON_BORDER_GREY: #b7b5ba;
$SIDEBAR_BACKGROUND: $DARK_GREY;
$NESTED_SIDEBAR_BACKGROUND: $WHITE_COLOR;
$NESTED_SIDEBAR_TEXT_COLOR: $LIGHT_GREY_TEXT_COLOR;
$SIDEBAR_TEXT_COLOR: $GREY;
$SIDEBAR_SELECT_OPTIONS_COLOR: #232226;
$DARK_BLUE_TEXT_COLOR: #984df7;
$ERROR_MESSAGE_COLOR: red;
$TRANSPARENT: rgba(0, 0, 0, 0);
$LIGHT_PURPLE_COLOR: rgb(106, 24, 237, 0.1);
$LIGHT_PURPLE_BORDER_COLOR: rgb(106, 24, 237, 0.5);
$TABLE_HEADER_TEXT_COLOR: #918e95;
$LG_SCREEN_BREAKPOINT: 992px;
$BLUE_COLOR: #0076d5;
$GRAY_COLOR: rgba(177, 177, 177, 0.23);
$GREEN_COLOR: #55ad36;

$FONT-1: 0.0625em;
$FONT-2: 0.125em;
$FONT-3: 0.1875em;
$FONT-4: 0.25em;
$FONT-5: 0.3125em;
$FONT-6: 0.375em;
$FONT-7: 0.4375em;
$FONT-8: 0.5em;
$FONT-9: 0.5625em;
$FONT-10: 0.625em;
$FONT-11: 0.6875em;
$FONT-12: 0.75em;
$FONT-13: 0.8125em;
$FONT-14: 0.875em;
$FONT-15: 0.9375em;
$FONT-16: 1em;
$FONT-17: 1.0625em;
$FONT-18: 1.125em;
$FONT-19: 1.1875em;
$FONT-20: 1.25em;
$FONT-21: 1.3125em;
$FONT-22: 1.375em;
$FONT-23: 1.4375em;
$FONT-24: 1.5em;
$FONT-25: 1.5625em;
$FONT-26: 1.625em;
$FONT-27: 1.6875em;
$FONT-28: 1.75em;
$FONT-29: 1.8125em;
$FONT-30: 1.875em;

$PRODUCT_STATS_CON_MAX_HEIGHT: 350px;
