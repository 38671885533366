.checkbox-field-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  margin: 0.3em 0em;
}
.checkbox-field {
  width: 1.25em;
  height: 1.25em;
  border-radius: 0.5em;
  accent-color: $PURPLE_COLOR;
}
.checkbox-field:hover {
  cursor: pointer;
}

.radio-button {
  accent-color: $PURPLE_COLOR;
}
