.upload-csv-wrapper {
  border: 2px dotted $LIGHT_GREY_TEXT_COLOR;
  width: 100%;
  border-radius: 0.75em;
  padding: 1.5em;
  opacity: 0.8;
  position: relative;
  transition: 0.4s ease;
}

.upload-csv-wrapper:hover {
  background-color: $LIGHT_PURPLE_COLOR;
}

.predefinded-column-label {
  font-size: 1.1em;
  font-weight: 500;
}

.import-inventory-columns-heading {
  font-size: 1em;
}

.import-inventory-column-header-wrapper {
  background-color: $GREY;
  color: $TABLE_HEADER_TEXT_COLOR;
  position: sticky;
  z-index: 10;
  top: 0;
}

.column-mapping-table-wrapper {
  position: relative;
  padding: 0px;
  border-radius: 0.75em;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
}

.import-customer-data-table {
  .rdt_Table {
    min-height: 5em;
    max-height: 20em;
  }
}

.import-select-field {
  .menu {
    max-height: 5em !important;
  }
}

.draggable-notification {
  position: absolute;
  cursor: grab;
  z-index: 100;
  width: 200px;
  height: 60px;
  padding: 1em;
  border-radius: 0.75em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: $WHITE_COLOR;
}
