.text-area-field {
  resize: vertical;
  max-height: 12em;
  min-height: 5em;
  position: relative;
  padding: 0.75em !important;
}
.textarea-field-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text-area-field:focus {
  outline: none;
}

.text-area-field::-webkit-scrollbar {
  width: 5px;
}

.text-area-field::-webkit-scrollbar-track {
  background: transparent;
  position: absolute;
  right: 0;
}

.text-area-field::-webkit-scrollbar-thumb {
  background: $DARK_GREY;
  border-radius: 100px;
  z-index: 2;
}
