.receipt-logo-image-wrapper {
  height: 10em;
  width: 10em;
  background-color: $WHITE_COLOR;
  overflow: hidden;
  position: relative;
  border-radius: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
}

.receipt-label-heading {
  font-family: "neue-june-demi";
  font-size: 1.25em;
}
.store-settings-switch-button-labels {
  font-size: 1.1em;
}
.refund-heading-wrapper {
  margin: 2.75em 0 -0.5em 0;
  p {
    margin-bottom: 0;
  }
}
.replace-logo-button {
  color: $WHITE_COLOR;
  background-color: $PURPLE_COLOR;
  border: none;
  height: 2em;
  width: 2em;
  padding: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  transition: 0.4s;
  position: absolute;
  bottom: 0.3em;
  right: 0.3em;
  overflow: hidden;
}

.replace-logo-button:hover {
  background-color: $DARK_GREY;
}

.receipt-logo-image {
  min-width: 50%;
  max-width: 100%;
  max-height: 100%;
}

.replace-icon {
  width: 1.7em;
  height: 1.7em;
}
