.receiptTotal {
  padding: 1em 0.5em;
  border-radius: 8px;
  background: #f4f4f4;
}
.receiptTotal-heading {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 0.04em;
  text-align: left;
  color: #918e95;
}
.receiptTotal-amount {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.4em;
  letter-spacing: 0.03em;
  text-align: left;
  color: #46454b;
}
.checkbox-field-text {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 0.04em;
  text-align: left;
}
