.tooltip {
  background-color: $lightGreyBgColor !important;
  border-radius: 0.75em !important;
  width: 10em !important;
  min-width: 12em !important;
  display: flex !important;
  flex-direction: column;
}

.tooltip:hover {
  opacity: 1 !important;
}
.applies-to-category {
  opacity: 1 !important;
  li {
    color: black;
    padding-left: 1.9em;
    margin: 0;
    margin-bottom: 0.5em;
    list-style-type: none;
    position: relative;
    &::before {
      content: ".";
      position: absolute;
      left: 0.2em;
      top: -0.47em;
      line-height: 1;
      margin-right: 0.5em;
      font-size: 2.3em;
    }
  }
}
