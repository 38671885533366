.system-tob-bar {
  background-color: #000000;
  color: $WHITE_COLOR !important;
  min-height: 40px !important;
  max-height: 40px !important;
  position: absolute;
  width: 100vw;
  z-index: 20;
}

.page-wrapper {
  max-width: 100vw;
  min-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.sidebar-wrapper {
  min-width: 80px;
  max-width: 80px;
  min-height: 100vh;
  max-height: 100vh;
  background-color: green;
  padding-top: 40px;
}

.nested-sidebar-opened {
  width: 100px;
}

.nested-sidebar-closed {
  width: 0px !important;
}

.outlet-container {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 45px;
  transition: 0.3s ease;
  position: relative;
  overflow: hidden;
}

.sidebar-opened-outlet-container {
  margin-left: 100px !important;
}
