.payout-card {
  background-color: $WHITE_COLOR;
  border-radius: 0.75em;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  span:first-child {
    font-size: 1.1em;
    opacity: 0.5;
  }
  span:nth-child(2) {
    font-size: 1.4em;
    font-weight: bold;
  }
  span:nth-child(3) {
    opacity: 0.5;
  }

  a {
    color: $PURPLE_COLOR;
    opacity: 1;
  }
}

.point-indicator {
  min-width: 0.7em !important;
  min-height: 0.7em !important;
  background-color: $PURPLE_COLOR;
  border-radius: 100px;
  margin-right: 0.5em;
}

.grey-color-indicator {
  background-color: rgba(217, 215, 219, 1);
}

.green-point-indicator {
  background-color: #55ad36;
}

.blue-point-indicator {
  background-color: #0076d5;
}
.payout-price {
  font-weight: 700;
  font-size: 1.2em;
}

.pay-button {
  border: none;
  text-decoration: underline;
  color: $PURPLE_COLOR;
  background-color: transparent;
}

.pay-button:disabled {
  cursor: not-allowed;
}
