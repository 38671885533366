.date-picker-container {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 3em !important;
  max-height: 3em !important;
  border-radius: 0.75em !important;
  box-shadow: "none" !important;
  background-color: #fcfafc !important;
  border-color: "#b7b5ba" !important;
  align-items: center !important;
  padding: 4px !important;
  padding-right: 5px;
  // border-radius: 8px;
}
.date-picker-container:hover {
  border: 1px solid #6a18ed;
}

.react-datepicker {
  border: none !important;
  border-radius: 0.75em !important;
  overflow: hidden !important;
  box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.5);
}

.react-datepicker__triangle {
  color: $WHITE_COLOR !important;
  fill: $WHITE_COLOR !important;
  stroke: $WHITE_COLOR !important;
}
.react-datepicker__navigation {
  margin-top: 1.5em !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
  min-width: 10em !important;
}
.react-datepicker-popper {
  z-index: 11 !important;
}
.css-1nmdiq5-menu {
  z-index: 11111 !important;
}
.react-datepicker__year-text,
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  padding: 0.5em !important;
}

.react-datepicker__year-wrapper {
  justify-content: center !important;
}

.react-datepicker__day--selected {
  background-color: #984df7 !important;
  color: $WHITE_COLOR !important;
}

.react-datepicker__year-text--selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #984df7 !important;
  color: $WHITE_COLOR !important;
  padding: 0.5em;
}

.react-datepicker__month-text:hover,
.react-datepicker__year-text:hover {
  background-color: $PURPLE_COLOR !important;
  color: $WHITE_COLOR !important;
}

.react-datepicker__month-container,
.react-datepicker__year--container {
  background-color: $WHITE_COLOR !important;
  padding: 1em 1em 1em 1em !important;
  border: none !important;
  .react-datepicker__header {
    background-color: $WHITE_COLOR;
    padding: 1em 0em !important;
  }
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0px 0px !important;
  border-radius: 0.09em !important;
  transition: ease 0.3s !important;
}

.react-datepicker__navigation:hover *::before {
  border-color: $PURPLE_COLOR !important;
}

.react-datepicker__input-container {
  input {
    width: auto !important;
    border: none !important;
    min-height: 2.5em;
    max-height: 2.5em;
    padding: 4px !important;
    border-radius: 8px;
    width: 100%;
    padding-left: 30px !important;
    padding-right: 10px !important;
    outline: none;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.25em;
    letter-spacing: 0.02em;
    text-align: left;
    cursor: pointer;
    padding: 0.5em 1em !important;
  }
}

.react-datepicker__day:hover {
  background-color: $PURPLE_COLOR !important;
  color: $WHITE_COLOR !important;
}

.date-icon-image {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em;
  z-index: 2;
  color: $DARK_GREY;
  margin-left: 0.5em;
  transition-duration: 0.3s;
  position: absolute;
  top: 4px;
}
.react-datepicker-ignore-onclickoutside {
  outline: none;
}

.date-time-picker-dropdown {
  min-width: 10em;
}

.datetime-background {
  // background-color: $WHITE_COLOR;
  min-height: 2.5em;
  max-height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0.2em 1em 1em !important;
  border-radius: 0.75em;
  box-shadow: 1px solid rgb(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  text-wrap: nowrap;
}

.datepicker-next-back-button {
  background-color: transparent;
  border: none;
}

.datetimepicker-container {
  padding: 0.3em;
  border: 1px solid $LIGHT_GREY_TEXT_COLOR;
  border-radius: 0.75em;
  max-height: 3em;
  min-height: 3em;
}

.dashboard-datepicker {
  background-color: #fcfafc !important;
  border: 1px solid $GREY;
  border-bottom: 2px solid $GREY;
  transition: 0.2s ease;
  &:hover {
    border-color: #6a18ed !important;
  }
  .react-datepicker__input-container {
    input {
      background-color: #fcfafc !important;
      text-align: center !important;
      // max-width: 10em !important;
    }
  }

  .date-picker-left-arrow-button {
    background-color: transparent;
    border: none;
    transition: 0.4s ease;
    padding: 0px !important;
    :hover {
      color: $PURPLE_COLOR;
    }
  }
  .date-picker-right-arrow-button {
    background-color: transparent;
    border: none;
    transition: 0.4s ease;
    padding: 0px !important;
    :hover {
      color: $PURPLE_COLOR;
    }
  }
}

.daily-datepicker {
  .react-datepicker__input-container {
    input {
      background-color: #fcfafc !important;
      text-align: center !important;
      max-width: 10em !important;
    }
  }
}

.monthly-datepicker {
  .react-datepicker-wrapper {
    min-width: 8em !important;
    max-width: 8em !important;

    .react-datepicker__input-container {
      input {
        max-width: 8em !important;
      }
    }
  }
}
