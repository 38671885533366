.toggle-button-container {
  width: 100%;
  height: 3.5em !important;
  // padding: 0.3em 0.3em;
  border-radius: 2em;
  background: #fcfafc;
  display: flex;
  column-gap: 0.3em;
}
.toggle-button-inner {
  padding: 0.5em;
  border-radius: 2em;
  background-color: transparent;
  border: none;
  color: $LIGHT_GREY_TEXT_COLOR;
  display: flex;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: 0.03em;
  text-align: left;
  color: #6b6970;
}
.light-purple-button-toggle {
  background-color: $LIGHT_PURPLE_COLOR !important;
  color: $PURPLE_COLOR !important;
}
