.vertical-number-field {
  border: 1px solid transparent;
  color: $DARK_GREY;
  background: $GREY;
  transition-duration: 0.3s;
  padding-left: 0em;
  max-width: 1.2em;
  text-align: center;
}
.vertical-number-field-2-digit {
  max-width: 2em;
}
.vertical-number-field-3-digit {
  max-width: 2.5em;
}
.vertical-number-field-4-digit {
  max-width: 3em;
}

.vertical-number-field-container {
  width: auto;
  border-radius: 0.4em;
  background: $GREY;
  display: flex;
  padding: 0.2em 0.2em;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  transition-duration: 0.6s;
  button {
    background-color: transparent;
    border: none;
    color: $LIGHT_GREY_TEXT_COLOR;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    position: relative;
    display: flex;
    justify-content: center;
  }
  button:hover {
    color: $DARK_GREY;
  }
}

.opened-cart-item-card {
  background: $GREY !important;
}

.vertical-number-field::placeholder {
  opacity: 0.5;
}

.vertical-number-field:focus {
  outline: none;
  box-shadow: none !important;
}
