@font-face {
  font-family: "neue-june-light";
  src: url("../../fonts/Inter/Inter_18pt-Light.ttf");
}

@font-face {
  font-family: "neue-june-thin";
  src: url("../../fonts/Inter/Inter_18pt-Thin.ttf");
}

@font-face {
  font-family: "neue-june-regular";
  src: url("../../fonts/Inter/Inter_18pt-Regular.ttf");
}

@font-face {
  font-family: "neue-june-medium";
  src: url("../../fonts/Inter/Inter_18pt-Medium.ttf");
}

@font-face {
  font-family: "neue-june-demi";
  src: url("../../fonts/Inter/Inter_18pt-SemiBold.ttf");
}

@font-face {
  font-family: "neue-june-bold";
  src: url("../../fonts/Inter/Inter_18pt-Bold.ttf");
}
