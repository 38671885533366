.tradeIns-heading {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 0.5em;
}
.global-trade-div {
  padding: 16px;
  //   background-color: red;
  background: #fcfafc;
  border-radius: 1em;
  margin: 0px;
}
.input-field-background-tradeIns {
  background: #f4f4f4;
}
.min-width-btn-trads {
  min-width: 9.25em;
  width: 100%;
}
.monthly-budget-heading {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: 0.03em;
  text-align: left;
  color: #6b6970;
  margin-bottom: 1.5em;
}
.category-added-div {
  max-height: 180px;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
}

.categories-data-table {
  .rdt_Table {
    min-height: 5em;
    max-height: 15em;
  }
}
.category-tooltip-wrapper {
  max-height: 120px;
}

.field-label-name {
  font-size: 1.2em;
}

.trade-in-item-tag-wrapper {
  background-color: $WHITE_COLOR !important;
  border-radius: 0.75rem !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
}
