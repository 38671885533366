.user-info-wrapper {
  max-height: Calc(100vh - 49px);
  padding-bottom: 1em;
}

.user-information-wrapper {
  max-height: Calc(100vh - 100px);
  padding-bottom: 5em;
}

.edit-inventory-wrapper {
  max-height: Calc(100vh - 95px);
}

.container-scrollbar {
  overflow: auto;
}

.container-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.container-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  position: absolute;
  right: 0;
}

.container-scrollbar::-webkit-scrollbar-thumb {
  background: $TABLE_HEADER_TEXT_COLOR;
  border-radius: 100px;
}

.user-info-image-wrapper {
  height: 10em;
  width: 10em;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-logo-image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 100%;
}

.replace-user-image-buuton {
  z-index: 2;
  right: 0.6em;
  bottom: 0.6em;
}
.password-fields-wrapper {
}
