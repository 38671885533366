.paragraph-skeleton {
  width: 10em !important;
}

.heading-skeleton {
  width: 10em !important;
  height: 1.5em !important;
}

.category-dropdown-skeleton {
  width: 12em !important;
  height: 3em !important;
  border-radius: 0.75em !important;
}

.hide-filter-dropdowns {
  height: 0px !important;
  overflow: hidden;
}
