.topbar {
  color: $SIDEBAR_SELECT_OPTIONS_COLOR;
  // border: 1px solid black;
  .topbar-title-container {
    // border: 1px solid blue;
    display: flex;
    justify-content: start;
    align-items: center;
    color: $WHITE_COLOR !important;
    min-height: 3em !important;
    .topbar-title {
      font-size: 1.2rem;
      color: $LIGHT_GREY_TEXT_COLOR;
      font-weight: 600;
    }
  }
  .topbar-user-con {
    // border: 1px solid yellow;
    div {
      // border: 1px solid pink;
    }
  }
  #user-drowndown-icon {
    path {
      transition-duration: 0.3s;
      transition-delay: 250ms;
      color: $WHITE_COLOR !important;
    }
  }

  .rotate-arrow {
    transform: scale(1, -1) translate(0px, -24px);
  }
  .tooltip {
    color: black;
    width: fit-content !important;
    cursor: pointer;
    opacity: 1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    div {
      color: $SIDEBAR_SELECT_OPTIONS_COLOR;
      ul {
        color: $SIDEBAR_SELECT_OPTIONS_COLOR;

        padding: 0px;
        margin: 0px;
        li {
          padding: 4px 0px;
          margin: 0px;
          text-wrap: nowrap;
          list-style-type: none;
          align-self: flex-start;
          color: $SIDEBAR_SELECT_OPTIONS_COLOR;
        }
        li:hover {
          background-color: rgba(0, 0, 0, 0.01);
          color: blue;
        }
      }
    }
  }
}

.topbar-info-icon {
  color: $WHITE_COLOR;
  border-radius: 100%;
  overflow: hidden;
  width: 3em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0em 0.2em 0em 0.7em;
  padding: 0.1em 0em 0em 0.1em;
  transition: all 0.4s ease;

  svg {
    width: 1.5em !important;
    height: 1.5em !important;
  }

  &:hover {
    background-color: $PURPLE_COLOR;
  }
}
