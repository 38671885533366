.input-field-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3em;
  max-height: 3em;
}

.input-field-wrapper:hover {
  .input-textfield {
    border-color: $PURPLE_COLOR;
  }
  .icon-image {
    color: $PURPLE_COLOR;
  }
}

.input-textfield {
  border: 1px solid transparent;
  width: 100%;
  color: $DARK_GREY;
  background-color: $WHITE_COLOR;
  padding: 0.5em 2.75em;
  border-radius: 0.75em;
  height: 100%;
  position: absolute;
  transition-duration: 0.6s;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
}

.input-textfield::placeholder {
  opacity: 0.5;
}
.input-textfield-without-icon {
  padding-left: 0.75em !important;
}
.input-textfield-with-Lefticon {
  margin-left: auto !important;
  background-color: $WHITE_COLOR;
}

.input-number-without-icon {
  padding-left: 0.5em !important;
}

.input-textfield:hover {
  border-color: $PURPLE_COLOR;
}

.input-textfield:focus {
  outline: none;
  box-shadow: none !important;
  border-color: $PURPLE_COLOR;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);
}

.input-textfield:disabled {
  cursor: not-allowed !important;
}
.icon-image {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em;
  z-index: 2;
  color: $DARK_GREY;
  margin-left: 0.75em;
  transition-duration: 0.3s;
  display: flex;
}

.cross-icon-image {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em;
  z-index: 2;
  color: $DARK_GREY;
  transition-duration: 0.3s;
}

.input-textfield:-webkit-autofill,
.input-textfield:-webkit-autofill:hover,
.input-textfield:-webkit-autofill:focus {
  border-color: $PURPLE_COLOR;
  transition: background-color 5000s ease-in-out 0s;
}

.field-label {
  color: $LIGHT_GREY_TEXT_COLOR;
  padding: 0.2em;
}

.input-number-field {
  padding-right: 0.5em;
}
.trade-batch-input-fields {
  background-color: #f4f4f4 !important;
  border-radius: 0.5em !important;

  &:disabled {
    color: rgba(0, 0, 0, 0.3) !important;
    background-color: #f4f4f4 !important;
    opacity: 1 !important;
  }
}

.input-number-field::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.number-field-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 0.5em;
  margin-left: auto;
  gap: 0.5em;
  background-color: transparent;
  button {
    background-color: transparent;
    border: none;
    color: $LIGHT_GREY_TEXT_COLOR;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
  }
  .number-field-arrow-icon {
    width: 0.8em !important;
  }
  button:hover {
    color: $DARK_GREY;
  }
}

.disabled-number-field {
  cursor: not-allowed !important;
  opacity: 0.7;
}

.disabled-input-field {
  cursor: not-allowed !important;
}
