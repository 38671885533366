.passwordHideIcon {
  width: 2em;
  z-index: 2;
  padding: 0;
  color: $LIGHT_GREY_TEXT_COLOR;
  margin-right: 0.5em;
  margin-left: auto;
  border: none;
  background-color: transparent;
  transition-duration: 0.4s;
}

.passwordHideIcon:hover {
  color: $PURPLE_COLOR;
}

.password-show-icon {
  width: 1.8em;
}
