.toggle-button-container {
  width: 100%;
  height: 48px;
  // padding: $FONT-5 $FONT-6;
  border-radius: 2em;
  background: #fcfafc;
  display: flex;
  column-gap: $FONT-6;
}
.toggle-button-inner {
  padding: $FONT-8;
  border-radius: 2em;
  background-color: transparent;
  border: none;
  color: $LIGHT_GREY_TEXT_COLOR;
  display: flex;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $FONT-18;
  font-weight: 400;
  line-height: $FONT-22;
  letter-spacing: 0.03em;
  text-align: left;
  color: #6b6970;
}
.light-purple-button-toggle {
  background-color: $LIGHT_PURPLE_COLOR !important;
  color: $PURPLE_COLOR !important;
}
