.login-card-wrapper {
  background: $DARK_GREY;
  background-image: url("../../images//LoginBackground.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}

.login-card {
  background: $lightGreyBgColor;
  h1 {
    font-size: 1.5em !important;
    font-weight: 900 !important;
  }
}

.login-form-wrapper {
  width: 100%;
}

.login-collect-pos-icon {
  color: $WHITE_COLOR;
  position: absolute;
  bottom: 1.5em;
  width: 6%;
}

.forgot-password-link {
  color: $PURPLE_COLOR !important;
  cursor: pointer;
  transition: 0.2s ease;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.logout-button {
  background-color: transparent;
  border: none;
  transition: 0.3s ease;
  position: relative;
  top: -0.5em !important;
  left: -2em !important;
  &:hover {
    color: $PURPLE_COLOR !important;
  }
}

.company-logo {
  width: 75% !important;
}

.company-name {
  font-size: 2.5rem !important;
}
