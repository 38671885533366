.loaderSpinner {
  position: relative;
  z-index: 999999;
}
.spinnerOverlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  z-index: 999998;
  display: flex;
  justify-content: center;
  align-items: center;
}
