.transaction-heading {
  font-size: 1.2em;
  font-weight: bold;
}
.type-dropdown {
  max-width: 13em;
}

.category-dropdown {
  max-width: 13em;
}

.searched-products-wrapper {
  padding: 1em;
  background-color: $WHITE_COLOR;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
  border-radius: 0.75em;
  max-height: Calc(100vh - 255px);
}
.searched-product-card {
  background-color: $lightGreyBgColor;
  padding: 1em;
  border: 1px solid transparent;
  border-radius: 0.75em;
  transition: 0.3s ease all;
}

.searched-product-card:hover {
  border: 1px solid $PURPLE_COLOR;
  cursor: pointer;
}

.add-product-button {
  border: none;
  background-color: transparent;
}

.add-button-icon {
  width: 1.5em;
  height: 1.5em;
}

.product-card-detail-left-div {
  width: 80%;
  .product-name-wrapper {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0.2em;
  }
  .serial-number-text {
    font-size: 0.8em !important;
  }
  span:nth-child(4) {
    color: $LIGHT_GREY_TEXT_COLOR;
    opacity: 0.7;
    max-width: 20%;
    white-space: nowrap;
    text-wrap: nowrap;
  }
}

.product-card-detail-right-div {
  display: flex;
  align-items: center;
  span:last-child {
    font-size: 1.1em;
  }
}

.new-sale-customer-image {
  width: 1.5em;
  max-width: 1.5em;
  height: 1.5em;
  max-height: 1.5em;
}

.cart-cards-wrapper {
  width: 100%;
  background-color: transparent;
  padding: 0.5em;
  max-height: Calc(100vh - 275px);
}

.trade-cart-cards-wrapper {
  width: 100%;
  background-color: transparent;
  padding: 0.5em;
  max-height: Calc(100vh - 350px);
}

.three-fields-open {
  max-height: Calc(100vh - 440px) !important;
}

.both-fields-open {
  max-height: Calc(100vh - 385px) !important;
}

.trade-both-fields-open {
  max-height: Calc(100vh - 470px) !important;
}

.one-field-open {
  max-height: Calc(100vh - 335px) !important;
}

.trade-one-fields-open {
  max-height: Calc(100vh - 420px) !important;
}
.cart-card {
  background-color: $WHITE_COLOR;
  padding: 0.5em 0.5em;
  border-radius: 0.75em;
  box-shadow: 0px 0px 5px rgb(0, 0, 0, 0.1);
}

.cart-inner-card {
  background: transparent !important;
  box-shadow: none !important;
}

.cart-card-name-wrapper {
  max-width: 30%;
  margin-right: auto;
  margin-left: 0.5em;
  span:first-child {
    font-size: 1.1em;
    font-weight: bolder;
    font-family: "neue-june-demi";
    margin-right: 0.1em;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
  span:nth-child(2) {
    font-size: 1.1em;
    font-weight: normal;
    margin-right: 0.1em;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;

    span {
      font-weight: 400;
      font-size: 01em;
    }
  }

  span:nth-child(3) {
    color: $LIGHT_GREY_TEXT_COLOR;
    opacity: 0.7;
    text-wrap: nowrap;
    // max-width: 8em;
    margin-right: 0.1em;
    overflow: hidden;
    padding-right: 0.1em;
    text-overflow: ellipsis !important;
  }
}

.cart-card-name-without-discount {
  max-width: 55%;
}
.cart-card-price-wrapper {
  span:first-child {
    padding: 0.5em 1em;
    background-color: $LIGHT_PURPLE_COLOR;
    border-radius: 100px;
  }
}
.cart-card-delete-button {
  border: none;
  background-color: transparent;
  transition-duration: 0.5s;
}
.cart-card-edit-icon {
  color: $PURPLE_COLOR !important;
}
.single-item-cart-item-discount {
  span:first-child {
    font-size: 1.1em !important;
    color: $DARK_GREY !important;
  }
}

.cart-card-delete-button:hover {
  color: $LIGHT_GREY_TEXT_COLOR;
}
.cart-card-discount-wrapper {
  padding-right: 0.3em;
  span:first-child {
    color: $LIGHT_GREY_TEXT_COLOR;
    opacity: 0.5;
  }
  .discounted-amount {
    color: $PURPLE_COLOR;
  }
}

.sale-extra-button {
  background: transparent;
  border: none;
  color: $DARK_GREY;
  transition: 0.4s ease;
}

.sale-extra-button:hover {
  opacity: 0.5;
}

.cart-details-data {
  span {
    font-size: 1em;
    font-weight: 700;
  }
}

.discount-method {
  color: $PURPLE_COLOR;
  font-size: 1.2em;
  font-weight: 700;
  margin-left: auto !important;
  margin-right: 0.75em;
}

.discount-number-field {
  min-width: 8em;
}
.note-area-field {
  max-height: 3.5em;
  min-height: 3.5em;
  padding: 0.5em 0.75em !important;
}

.active-link {
  color: $PURPLE_COLOR;
}

.gift-card-amount-field {
  min-width: 12em;
}

.balance-wrapper {
  span:first-child {
    font-size: 1.3em;
  }
  span:last-child {
    font-size: 1.3em;
    font-weight: 700;
  }
}

.item-count-chip {
  background-color: $LIGHT_PURPLE_COLOR;
  padding: 0.5em 1em;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $DARK_GREY;
}

.payment-cart {
  .cart-items-wrapper {
    max-height: 9em;
    padding: 0em 0.5em;
  }
  .payment-cart-productname {
    font-size: 1.1em;
    font-weight: 700;
    max-width: 10em;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    padding-right: 0.2em;
  }
  .subtotal-heading {
    font-size: 1.1em;
  }
  .product-disocunt-label {
    max-width: 20em;
  }
}

.payment-div-wrapper {
  background-color: $WHITE_COLOR;
  border-radius: 0.75em;
  padding: 1em;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);

  .amount-to-pay-heading {
    font-size: 1.1em;
    font-weight: 700;
  }

  .selected-customer-wrapper {
    padding: 1em;
    background-color: $lightGreyBgColor;
    border-radius: 0.75em;

    img {
      width: 4em;
      max-width: 4em;
    }
    .customer-credit-wrapper {
      background-color: $GREY;
      padding: 1em;
      border-radius: 0.75em;
      span:first-child {
        font-size: 1.1em;
        color: $TABLE_HEADER_TEXT_COLOR;
      }
      span:last-child {
        font-size: 1.2em;
        font-weight: 700;
      }
    }

    .selected-customer-wrapper {
      margin-right: auto;
      span:first-child {
        font-size: 1.2em;
        font-weight: 700;
      }
      span:last-child {
        font-size: 1.1em;
        opacity: 0.5;
      }
    }
  }
}

.trade-cart-cart-sku {
  font-weight: 400 !important;
}

.payment-type-button {
  padding: 2em 1em;
}

.store-credit-input {
  background-color: $lightGreyBgColor;
}

.input-textfield-table-cart {
  border: 1px solid transparent;
  color: $DARK_GREY;
  background-color: $WHITE_COLOR;
  transition-duration: 0.6s;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
  background-color: #eae8ec;
  width: 90px;
  padding-left: 0.3em;

  height: 22px;
  padding: 0.3em 0.5em;
  border-radius: 1.25em;
}
.input-textfield-table-wrapper-cart {
  width: 100%;
  // justify-content: center;
}
.input-textfield-table-cart:focus {
  outline: none;
  box-shadow: none !important;
}
.payment-cash-card-wrapper {
  background-color: $WHITE_COLOR;
  border-radius: 0.75em;
  padding: 0.75em;

  .cash-heading {
    font-size: 1.2em;
    font-weight: 700;
  }
}

.receipt-total-wrapper {
  padding: 0.75em;
  background-color: $lightGreyBgColor;
  border-radius: 0.75em;

  span:first-child {
    opacity: 0.7;
  }
  span:nth-child(2) {
    font-size: 1.2em;
    font-weight: 700;
  }
}
.fixed-amount-button {
  min-width: 1em !important;
  min-height: auto;
  padding: 0.5em 1.25em;
  border-radius: 100px;
}

.barcode-payment-field {
  background-color: $lightGreyBgColor;
}

.amount-to-paid-field-wrapper {
  min-height: 3em;
  max-height: 3em;
  min-width: 10em;
  background-color: $LIGHT_PURPLE_COLOR;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  transition-duration: 0.3s;

  .dollar-icon-alignment {
    position: relative;
    margin-left: 1em;
    margin-right: auto;
  }

  .amount-to-paid-field {
    background-color: transparent;
    border: none;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0.5em 0.5em;
    padding-left: 2em;
    &:disabled {
      cursor: not-allowed;
      background-color: #f4f4f4 !important;
      color: rgb(0, 0, 0, 0.6) !important;
    }
  }

  .amount-to-paid-field:focus {
    outline: none;
  }
}

.amount-to-charge-field-wrapper {
  border-radius: 0.5em !important;
  border-color: $LIGHT_GREY_TEXT_COLOR;
  background-color: transparent;
}

.amount-to-paid-field-wrapper:hover {
  border: 1px solid $PURPLE_COLOR;
}

.customer-email-wrapper {
  padding: 0.7em 1em;
  min-height: 3em;
  max-height: 3em;
  background-color: $lightGreyBgColor;
  border-radius: 0.75em;
  font-weight: 700;
  min-width: 15em;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.receipt-label {
  min-width: 8em;
}

.selected-customer {
  border-radius: 0.75em;

  img {
    width: 4em;
    max-width: 4em;
  }
  .customer-name-heading {
    font-size: 1.1em;
    font-weight: 700;
  }
  .customer-credit {
    background-color: $GREY;
    padding: 1em;
    border-radius: 0.75em;
    span:first-child {
      font-size: 1.1em;
      color: $TABLE_HEADER_TEXT_COLOR;
    }
    span:last-child {
      font-size: 1.2em;
      font-weight: 700;
    }
  }
  .customer-reset {
    color: $PURPLE_COLOR;
    min-width: unset;
  }
}

.verifone-heading {
  font-size: 1.8em !important;
  text-align: center;
  color: $LIGHT_GREY_TEXT_COLOR;
  opacity: 0.7;
}

.product-cart-card-price {
  min-width: 8em;
}

.product-name-detail-sale {
  background-color: $PURPLE_COLOR;
  color: $WHITE_COLOR;
  width: auto;
  max-width: 30%;
  padding: 0.5em;
  border-radius: 0.75em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gift-card-history-heading {
  font-size: 0.9em !important;
}

.checkout-section-wrapper {
  max-height: 78vh;
}

.payment-cart-wrapper {
  max-height: 80vh !important;
}

.payment-cart-item-wrapper {
  max-height: 19em !important;
}

.payment-history-wraapper {
  max-height: calc(100vh - 330px) !important;
}

.marginLeft-auto {
  margin-left: auto;
}

.sale-cart-section {
  background-color: $WHITE_COLOR;
  border-radius: 0.25em;
  box-shadow: -1px 0px 7px rgb(0, 0, 0, 0.05);
  height: calc(100vh - 50px);
}
