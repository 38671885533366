.return-chart-div {
  background: #fcfafc;
  margin: 0px;

  padding: 0.5em;
  border-radius: 0.5em;
}
.return-search-div {
  background: #fcfafc;
  margin: 0px;

  padding: 0.5em;
  border-radius: 0.5em;
}
.new-return-text {
  span {
    margin-left: 0.5em;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 0.04em;
    text-align: left;
    color: #918e95;
  }
}
.return-product-name {
  font-size: $FONT-20;
  font-weight: 600;
  line-height: $FONT-25;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 0px;
}
.return-product-sku-num {
  font-size: $FONT-16;
  font-weight: 400;
  line-height: $FONT-20;
  letter-spacing: 0.04em;
  text-align: left;
  color: #918e95;
  margin-bottom: 0px;
}
.return-amount-chip {
  background: #6a18ed1a;
  padding: $FONT-6 $FONT-8 $FONT-6 $FONT-8;
  border-radius: $FONT-24;
  max-width: 85px;
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.price-charting-text-return {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6a18ed;
  text-decoration: underline;
}
.return-payment-add {
  font-size: $FONT-16;
  font-weight: 400;
  line-height: $FONT-20;
  letter-spacing: 0.04em;
  text-align: left;
  color: #918e95;
}
.return-payment-note {
  font-size: $FONT-16;
  font-weight: 600;
  line-height: $FONT-20;
  letter-spacing: 0.03em;
  text-align: left;
  color: #232226;
}
.gray-icon-color {
  color: #918e95;
}
.return-payment-tax-note {
  font-size: $FONT-16;
  font-weight: 400;
  line-height: $FONT-20;
  letter-spacing: 0.03em;
  text-align: left;
  color: #232226;
}
.payment-icon-button-text {
  font-size: $FONT-18;
  font-weight: 400;
  line-height: $FONT-22;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  span {
    font-size: $FONT-12;
    font-weight: 400;
    line-height: $FONT-14;
    letter-spacing: 0.03em;
    text-align: left;
  }
}
.payment-return-amount-text {
  font-size: $FONT-18;
  font-weight: 500;
  line-height: $FONT-22;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.payment-icon-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.payment-button-children {
  width: 100%;
  padding: $FONT-12;
}

.return-profile-img img {
  width: 32px;
  height: 32px;
}
.return-name-text {
  font-size: $FONT-16;
  font-weight: 600;
  line-height: $FONT-20;
  letter-spacing: 0.03em;
  text-align: left;
  display: flex;
  align-items: center;
}
.return-name-email {
  font-size: $FONT-14;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.04em;
  text-align: center;
  display: flex;
  align-items: center;
}
.return-credit-store-text {
  font-size: $FONT-11;
  font-weight: 400;
  line-height: $FONT-14;
  letter-spacing: 0.04em;
  text-align: center;
  margin-bottom: $FONT-7;
}
.return-credit-store-amount {
  font-size: $FONT-14;
  font-weight: 600;
  line-height: $FONT-17;
  letter-spacing: 0.03em;
  text-align: center;
  margin-bottom: 0px;
}
.return-name-credit-store {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.refund-type {
  font-size: $FONT-18;
  font-weight: 600;
  line-height: $FONT-30;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(35, 34, 38, 1);
}
.refund-type-to {
  color: rgba(35, 34, 38, 0.57);
  margin: 0px 6px;
  font-size: $FONT-18;
  font-weight: 600;
  line-height: $FONT-30;
  letter-spacing: 0.03em;
  text-align: left;
}
.refund-type-items {
  padding: $FONT-8;
  border-radius: 2em;
  background: #6a18ed1a;
  margin: 0px $FONT-10;
  font-size: $FONT-12;
  font-weight: 400;
  line-height: $FONT-20;
  letter-spacing: 0.03em;
  text-align: left;
}
.return-items-modal {
  font-size: $FONT-14;
  font-weight: 400;
  line-height: $FONT-20;
  letter-spacing: 0.03em;
  text-align: left;
  color: #918e95;
}
.return-product-name-modal {
  font-size: $FONT-16;
  font-weight: 400;
  line-height: $FONT-20;
  letter-spacing: 0.03em;
  margin: 0px $FONT-8;
}
.return-product-totals-modal {
  font-size: $FONT-16;
  font-weight: 700;
  line-height: $FONT-20;
  letter-spacing: 0.03em;
  margin: 0px $FONT-8;
}
.return-amount-modal {
  font-size: $FONT-16;
  font-weight: 500;
  line-height: $FONT-20;
  letter-spacing: 0.03em;
  text-align: left;
}
.background-gray {
  background: #eae8ec;
}
.return-card-wrapper {
  max-height: 48vh;
  padding-bottom: 1em;
}

.tradein-search-div-wrapper {
  box-shadow: 1px 1px 10px rgb(0, 0, 0, 0.3);
}

.input-textfield-table {
  border: 1px solid transparent;
  color: $DARK_GREY;
  background-color: $WHITE_COLOR;
  transition-duration: 0.6s;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
  background-color: #eae8ec;
  width: 50px;
  padding-left: $FONT-5;

  height: 22px;
  padding: $FONT-6, $FONT-8;
  border-radius: $FONT-24;
}
.input-textfield-table-wrapper {
  width: 100%;
  // justify-content: center;
}
.input-textfield-table:focus {
  outline: none;
  box-shadow: none !important;
}
.note-text-area {
  padding: 0.3em 0.3em;
  width: 100%;
  color: $DARK_GREY;
  background-color: $WHITE_COLOR;
  border-radius: 0.75em;
  transition-duration: 0.6s;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
}
.note-text-area:hover {
  border-color: $PURPLE_COLOR;
}

.note-text-area:focus {
  outline: none;
  box-shadow: none !important;
  border-color: $PURPLE_COLOR;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);
}
