@keyframes slideInFromRight {
  0% {
    left: 46%;
  }
  100% {
    left: 0%;
  }
}

@keyframes slideInFromLeft {
  0% {
    left: 0%;
  }
  100% {
    left: 46%;
  }
}

.topbar-toggle-button-wrapper {
  background-color: $WHITE_COLOR;
  padding: 0.5em;
  min-height: 3em;
  max-height: 3em;
  border-radius: 100px;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  position: relative;
  margin-left: 1em;
}

.topbar-toggle-button {
  border-radius: 100px;
  background-color: transparent;
  max-height: 100% !important;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $DARK_GREY;
  padding: 0em 2em;
  text-wrap: nowrap;
  z-index: 2;
  transition: 0.1s ease all;
}

.active-Button {
  color: $PURPLE_COLOR;
  font-weight: 600;
}

.topbar-toggle-button-background {
  background-color: $LIGHT_PURPLE_COLOR;
  position: absolute;
  height: 80%;
  min-width: 50%;
  margin: 0.4em;
  border-radius: 100px;
  left: 0%;
  animation: slideInFromRight 0.5s ease;
}

.new-trade-active {
  left: 46%;
  animation: slideInFromLeft 0.5s ease;
}
