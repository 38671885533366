.form-portion-heading {
  color: var(--POS-Gray-8, #232226);
  font-size: 1.25em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  margin-bottom: 1.25em;
}
.Notification-Container {
  background-color: #fcfafc;
  padding: 1em;
  border-radius: 12px;
}
.notification-card-div {
  padding-right: 4px !important;
  max-height: 285px;
  overflow-y: auto; /* Only shows scrollbar when necessary */
  overflow-x: hidden;
}

.alert-setting-text {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: 0.04em;
  text-align: left;
}
.search-Field-Bg {
  background-color: rgb(244, 244, 244);
}

.Pokeman-Card-Div {
  width: 100%;
  //   height: 87px;
  padding: 8px;
  border-radius: 12px;
  background: rgba(244, 244, 244, 1);
  margin-top: 8px !important;
  display: flex;
}
.img-col img {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}
.sku-name {
  font-size: 1.1em;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 0.03em;
  color: rgba(35, 34, 38, 1);
  max-width: 15em;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  padding-right: 0.3em;
}
.sku-number {
  font-size: 0.75em;
  font-weight: 400;
  line-height: 0.93em;
  letter-spacing: 0.03em;
  text-align: left;
  color: #6a18ed;
  max-width: 15em;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.3em;
}
.sku-type {
  font-size: 0.87em;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: 0.03em;
  max-width: 16em;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.3em;
}
.remove-button-min-width {
  min-width: 0em;
  margin-left: 1em;
}

.customer-count-chip {
  padding: 0.5em 1em;
  background-color: $LIGHT_PURPLE_COLOR;
  border-radius: 100px;
  margin-left: 1em;
  color: $DARK_GREY;
}

.card-delete-button {
  cursor: pointer;
  transition: 0.4s ease;
}

.card-delete-button:hover {
  .icon-image {
    color: $PURPLE_COLOR;
  }
}

.copy-button {
  border: none;
  background: none;
  padding: 0em;
}

.css-1xhuun6-option:has(.add-customer-button-select):hover {
  background-color: unset;
}
