.transaction-overview-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 0.5em;
  background-color: $WHITE_COLOR;
  padding: 0.6em;
  max-width: 16em;
  min-width: 16em;
  border-radius: 0.75em;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);

  p {
    margin: 0;
  }

  .transaction-overview-card-heading {
    font-size: 1em;
    color: $TABLE_HEADER_TEXT_COLOR;
  }

  .transaction-overview-card-description {
    font-size: 1.25em;
    font-weight: 600;
  }
}
.transaction-overview-card-wrapper {
  margin-top: 1em;
  margin-bottom: 1.2em;
}
