.dashboard-dropdown-type {
  min-width: 15em;
  max-width: 15em;
}
.dashboard-dropdown-category {
  min-width: 15em;
  max-width: 15em;
}
.dashboard-dropdown-date {
  min-width: 30em;
  max-width: 30em;
}
.dashboard-card-wrapper {
  background-color: $WHITE_COLOR;
  padding: 1.2em;
  border-radius: 0.75em;
  box-shadow: 0px 0px 3px rgb(0, 0, 0, 0.1);
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .card-heading {
    font-family: "neue-june-demi";
    font-size: 1.2em;
  }
  .dashboard-mini-inner-card {
    background-color: $lightGreyBgColor;
    padding: 1em;
    border-radius: 0.75em;
    margin: 0.5em 0em;
    span:first-child {
      color: $LIGHT_GREY_TEXT_COLOR;
    }
    span:last-child {
      font-size: 1.2em !important;
      font-family: "neue-june-demi";
    }
  }

  .wallet-icon {
    color: $PURPLE_COLOR;
    width: 2em;
    margin-bottom: 0.2em;
  }

  .card-wrapper-wallet {
    span:first-child {
      color: $LIGHT_GREY_TEXT_COLOR;
    }
    span:last-child {
      font-size: 1.2em !important;
      font-family: "neue-june-demi";
    }
  }
}

.graph-card-wrapper {
  height: 26vh !important;
  min-height: 17.5em;
}

.top-selling-items {
  height: auto !important;
  min-height: 42vh;
}

.glance-wrapper {
  height: 26vh !important;
  min-height: 17.5em;
}

.avg-order-value-wrapper {
  height: 13vh !important;
  min-height: 10em;
}

.square-card-wrapper-min-height {
  height: 14vh !important;
  min-height: 8em;
}

.remaing-purchase-power-min-height {
  height: 10vh !important;
}

.trend-icon {
  color: $PURPLE_COLOR;
}

.light-text-color {
  color: $LIGHT_GREY_TEXT_COLOR;
}

.square-card-wrapper {
  background-color: $WHITE_COLOR;
  padding: 1em;
  border-radius: 0.75em;
  box-shadow: 0px 0px 3px rgb(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  justify-content: center;
  height: 10vh;
  min-height: 10em;
}

.square-card-icon {
  width: 1.5em;
  height: 1.5em;
  color: $PURPLE_COLOR;
}

.square-card-price-heading {
  font-size: 1.2em !important;
  font-family: "neue-june-demi";
}

.edit-budget-button {
  color: $PURPLE_COLOR;
  font-size: 1em;
  font-weight: 600;
  background-color: transparent;
  border: none;
}

.edit-purchasing-power-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.purchasing-power-field {
  min-width: 10em;
  max-width: 10em;
}

.in-stock-column {
  color: $PURPLE_COLOR;
}

.close-button-purchasing-power {
  width: 1.5em;
  height: 1.5em;
}
.edit-buttons {
  background-color: transparent;
  border: none;
}
.active-card {
  border: 1px solid $PURPLE_COLOR;
}

.graph-datepicker-container {
  .graph-datepicker {
    background-color: $lightGreyBgColor;
    display: flex;
    align-items: center;
    min-height: 3em;
    max-height: 3em;

    .react-datepicker__input-container {
      input {
        padding-left: 0em !important;
      }
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: #984df7 !important;
    color: $WHITE_COLOR;
  }

  .react-datepicker-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $lightGreyBgColor;
    padding-right: 0.5em;
    border-radius: 0.75em;
    min-width: 10em !important;
    .datepicker-buttons-wrapper {
      display: flex;
      flex-direction: column;
      max-height: 3em;
      min-height: 3em;
      .datepicker-next-button {
        background-color: transparent;
        border: none;
        transition: 0.4s ease;
        padding-top: 0.3em;
        .datepicker-next-icon {
          width: 1.1em !important;
          transform: rotate(90deg);
        }
      }

      .datepicker-next-button:hover {
        color: $PURPLE_COLOR;
      }

      .datepicker-previous-button {
        background-color: transparent;
        border: none;
        transition: 0.4s ease;
        .datepicker-previous-icon {
          transform: rotate(90deg);
          margin-top: -1.7em;
          width: 1.1em !important;
        }
      }

      .datepicker-previous-button:hover {
        color: $PURPLE_COLOR;
      }
    }
  }
  .react-datepicker__input-container {
    input {
      min-width: 10em;
      max-width: 10em;
      padding-left: 1em !important;
    }
  }

  .graph-date-picker-icon {
    background-color: $lightGreyBgColor;
    z-index: 5;
    min-height: 3em;
    max-height: 3em;
    color: rgb(0, 0, 0, 0.5);
    position: absolute;
    right: 0.75em;
    border-radius: 0.75em !important;
    overflow: hidden;
  }
}

.down-trend-icon {
  color: rgb(220, 53, 69);
  transform: rotateX(180deg);
}

.dashboard-max-width {
  max-width: 75vw !important;
}

.no-graph-data-wrapper {
  height: 160px !important;
  p:first-child {
    font-size: 1.5em !important;
    font-weight: 900;
    margin: 0;
  }
  p:last-child {
    opacity: 0.5;
  }
}

.category-bar {
  background-color: transparent !important;
  overflow: visible !important;
  display: flex !important;
  gap: 0.5em !important;
  // justify-content: center !important;
  align-items: center !important;
  height: 1rem !important;
  .progress-bar {
    background-color: $PURPLE_COLOR !important;
    border-radius: 0.375rem !important;
    height: 1rem !important;
  }

  span {
    font-size: 1.2em !important;
    min-width: 1em !important;
  }
}

.category-data-found {
  height: auto !important;
  padding: 3rem 0rem 2rem 0rem !important;
}

.sale-summary-report-icon {
  cursor: pointer;
  color: #2197ff;
  transition: 0.3s ease;

  &:hover {
    color: $DARK_GREY;
  }
}
