.billing-card-wrapper {
  background-color: $WHITE_COLOR;
  border-radius: 0.75em;
  padding: 3em 3em;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
  // margin-top: -5%;

  span:first-child {
    font-size: 1.3em;
    font-weight: 500;
  }

  .billing-card-price-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;

    span:first-child {
      font-size: 2.5em;
    }
  }

  .payments-methods-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .paymnet-mmethod-heading {
      font-size: 1em !important;
    }
  }
}
