.sidebar,
.nested-sidebar {
  transition-duration: 0.3s;
  position: relative;
  background-color: $SIDEBAR_BACKGROUND;
  color: $SIDEBAR_TEXT_COLOR;
  min-height: 100vh;

  .sidebar-title-container {
    overflow: show;
    width: 50em;
    max-width: 50em;
  }
  ul {
    li {
      transition-duration: 0.3s;
      list-style-type: none;
      padding: 1.5em 0px;
      width: 100%;
      cursor: pointer;
      font-size: 0.95rem;
      font-weight: 600 !important;
    }
    .pos-heading {
      color: $DARK_BLUE_TEXT_COLOR;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.78px;
    }
    overflow: hidden;
  }
  * {
    box-sizing: border-box;
  }
  .toggle-sidebar-btn {
    all: unset;
    position: absolute;
    right: -12px;
    top: 15px;
    // background-color: $DARK_BLUE_TEXT_COLOR;
    // color: $SIDEBAR_TEXT_COLOR;
    // border-radius: 50%;
    // border-color: transparent;
    // padding: 4px 8px;
    z-index: 10;
    cursor: pointer;
    .toggle-icon {
      transition-duration: 0.4s;
    }
  }
  .toggle-nested-sidebar-btn {
    z-index: 15;
  }
  .toggle-sidebar-rotate {
    .toggle-icon {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }
  .active-sidebar-btn {
    background-color: $WHITE_COLOR;
    color: $DARK_BLUE_TEXT_COLOR;
    position: relative;
    // margin: 0;
  }
  .active-sidebar-btn:after {
    content: "";
    height: 30%; //You can change this if you want smaller/bigger borders
    width: 3px;
    border-radius: 5px;

    position: absolute;
    left: 0;
    top: 50%; // If you want to set a smaller height and center it, change this value
    transform: translateY(-50%);

    background-color: $DARK_BLUE_TEXT_COLOR; // The color of your border
  }
}
.nested-sidebar {
  background-color: $NESTED_SIDEBAR_BACKGROUND;
  color: $NESTED_SIDEBAR_TEXT_COLOR;
  position: absolute;
  top: 40px;
  left: 80px !important;
  .active-nested-sidebar-btn {
    background-color: rgba(0, 0, 255, 0.1);
    color: $PURPLE_COLOR;
    position: relative;
    font-weight: 600;
  }
  ul {
    margin: 0px;
    margin-top: 80px;
    li {
      padding: 8px 10px 10px 8px;
      transition-duration: 0s;
    }
  }
}
.width-0 {
  width: 0px !important;
}

.sidebar-footer-wrapper {
  padding: 1em 0.5em;
}

.collect-pos-footer-logo {
  width: 3.5em;
  max-width: 3.5em;
}
