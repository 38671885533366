.searched-products-wrapper-trade {
  padding: 1em;
  background-color: $WHITE_COLOR;
  box-shadow: 0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),
    2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),
    5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01);
  border-radius: 0.75em;
  max-height: Calc(100vh - 290px);
}

.edit-total-cash-trade-wrapper {
  border-radius: 0.75em !important;
  background-color: $WHITE_COLOR !important;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);

  .batch-edit-offer-heading {
    font-size: 1.3em !important;
    font-family: "neue-june-demi";
  }

  .link-unlink-button-icon {
    color: $PURPLE_COLOR !important;
    width: 2.2em !important;
    height: 2.2em !important;
  }
  .edit-trade-batch-offer-field-heading {
    font-size: 1.3em !important;
    opacity: 0.7;
  }

  .edit-trade-batch-offer-field {
    background-color: $GREY;
    width: 100%;
    border-radius: 0.75em;
    padding: 0.75em;
    font-size: 1.1em;

    span {
      font-size: 0.8em !important;
    }
  }
}
.reset-total-button {
  color: $PURPLE_COLOR !important;

  &:disabled {
    cursor: no-drop !important;
  }
  .link-unlink-button-icon {
    width: 2.2em !important;
    height: 2.2em !important;
  }
}
.edit-cash-trade-wrapper {
  .disabled-icon {
    cursor: not-allowed !important;
  }
  .total-cash-wrapper {
    cursor: pointer;
    border-radius: 0.75em;
    padding: 1em !important;
    .total-cash-heading {
      font-size: 1.3em !important;
      font-weight: 700;
    }

    .edit-total-button {
      color: $PURPLE_COLOR !important;
      position: absolute;
      top: 0.5em;
      right: 0.5em;

      &:disabled {
        cursor: no-drop !important;
      }
      .edit-offer-icon {
        width: 2.2em !important;
        height: 2.2em !important;
      }

      &:hover {
        color: $BLACK_COLOR;
      }
    }

    .edit-cash-trade-offer-value {
      opacity: 0.8;
      span:first-child {
        font-size: 1.3em !important;
        margin-right: 0.1em !important;
      }
    }
  }
}
.edit-offer-modal {
  z-index: 10;
  background-color: $WHITE_COLOR !important;
  width: 18em !important;
  height: 11em !important;
  position: absolute;
  top: -5em;
  right: -5em;
  opacity: 1 !important;
  border-radius: 0.75em !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1),
    0px 0px 8px 0px rgba(0, 0, 0, 0.1), 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  .edit-offer-modal-heading {
    font-size: 1.1em !important;
    font-family: "neue-june-demi" !important;
  }
  .edit-offer-modal-toggle-button {
    width: 6em !important;
    min-width: 6em !important;
    min-height: 2.3em !important;
    max-height: 2.3em !important;
    font-size: 0.9em !important;
  }
  .edit-offer-modal-button {
    min-width: 7em !important;
    max-width: 7em !important;
    max-height: 2.5em !important;
    min-height: 2.5em !important;
  }
}

.edit-trade-offer-modal {
  right: 0em !important;
}
.total-cash-wrapper-active {
  background-color: rgb(106, 24, 237, 0.2);
  .total-cash-heading {
    color: $PURPLE_COLOR !important;
  }
}

.trade-cart-market-value {
  span {
    opacity: 0.8;
    font-size: 1.2em !important;
    font-family: "neue-june-demi";
  }
}
